import { post } from '@/utils/request'

//获取站点列表
export function getSiteServiceTypeList(data) {
    return post('/edu/site_service_class/classList', data)
}
//添加站点
export function addSiteServiceType(data) {
    return post('/edu/site_service_class/addClass', data)
}
//站点回显
export function getInfo(data) {
    return post('/edu/site_service_class/classInfo', data)
}

//站点编辑
export function editSiteServiceType(data) {
    return post('/edu/site_service_class/editClass', data)
}
//站点删除
export function delSiteServiceType(data) {
    return post('/edu/site_service_class/delClass', data)
}
