<template>
  <div>
    <el-card>
      <all-log :dialogLog="dialogLog" />

      <div class="menu-box">
        <h2>站点服务类型管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()"
                >添加站点服务类型</el-button
              >
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="class_name" label="站点服务名称">
            </el-table-column>

            <el-table-column prop="class_status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.class_status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.class_status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="class_name"
          label="站点服务类型名称"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.class_name"
            placeholder="请填写站点服务类型名称"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="addOrEdit === 2"
          prop="class_status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.class_status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" @click="confirmAE()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSiteServiceTypeList,
  addSiteServiceType,
  getInfo,
  editSiteServiceType,
  delSiteServiceType,
} from '@/api/edu/siteservicetype.js'

export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },
      optionProps: {
        checkStrictly: false,
        value: 'id',
        label: 'cname',
        children: 'sub',
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        class_name: {
          required: true,
          message: '请填写站点服务类型名称',
          trigger: 'blur',
        },
      },
      title: '添加站点服务类型',
      addOrEdit: 1,
      formLabelWidth: '160px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      form: {
        class_name: '',
      },
      tableData: [],
      provList: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getSiteServiceTypeList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加服务地址'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        class_name: '',
      }
      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      this.dialogVisible = true
      this.title = '编辑站点服务类型'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        class_id: row.class_id,
      })
      console.log(res.data)
      this.form.class_name = res.data.class_name
      this.form.class_status = res.data.class_status
      this.form.class_id = res.data.class_id
    },

    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delSiteServiceType({
            class_id: row.class_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加

            const { data: res } = await addSiteServiceType(this.form)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
          } else {
            // 编辑

            if (this.isEdit === true) {
              const { data: res } = await editSiteServiceType(this.form)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
